import React, {Component}  from "react";

class PriceItem extends Component {

    render() {
        const {label, price} = this.props;

        return (
            <tr>
                <td>{label}</td>
                <td>{price}</td>
            </tr>
        );
    }
}

export default PriceItem;