import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './main.scss';
import React, {Component}  from "react";
import {graphql, StaticQuery, Link} from 'gatsby';
import getFrontmatter from '../helpers/getFrontmatter';
import {MDBCard, MDBCardBody, MDBCardTitle, MDBNav, MDBNavItem, MDBTabContent, MDBTabPane} from "mdbreact";
import StyledBackgroundSection from "../components/background";
import MenuContainer from "../components/menu-container";
import PriceItem from "../components/price-item";

export default class PriceListPage extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            activeItem: "1"
        };
    }
    
    toggle = tab => e => {
        e.preventDefault();
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };

    buildPriceItem(priceItem, index) {
        return <PriceItem index={index} label={priceItem.naam} price={priceItem.prijs} />;
    }
    
   render() {
       return (
       <StaticQuery
           query={graphql`
              query {
                allMarkdownRemark(filter: {fields: {slug: {eq: "/prijslijst/"}}}) {
                    edges {
                      node {
                        frontmatter {
                          pagina_titel,
                          pagina_omschrijving,
                          prijslijst {
                            dames {
                                prijs,
                                naam
                            },
                            heren {
                                prijs,
                                naam
                            }
                            kinderen {
                                prijs,
                                naam
                            }
                          }
                        }
                      }
                    }
                 }
                desktop: file(relativePath: { eq: "prijslijst.jpg" }) {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            `}
            render={data => {
                const frontmatter = getFrontmatter(data);
                const {pagina_titel, pagina_omschrijving, prijslijst} = frontmatter;
                const image = data.desktop.childImageSharp.fluid;
        
                const menPriceItem = prijslijst.heren.map((price, index) => {
                    return this.buildPriceItem(price, index + 100);
                });
                const womanPriceItem = prijslijst.dames.map((price, index) => {
                    return this.buildPriceItem(price, index + 300);
                }); 
                const childPriceItem = prijslijst.kinderen.map((price, index) => {
                    return this.buildPriceItem(price, index + 600);
                }); 
                

                return (
                    <StyledBackgroundSection className="contact-background" image={image}>
                        <div className="price-list">
                           <MenuContainer />
                           <div className="container">
                               <div className="row justify-content-end">
                                   <div className="col-8">
                                       {/*<Router history={history}>*/}
                                           <MDBCard className="mt-5 mb-5">
                                               <MDBCardBody className="border-bottom border-primary">
                                                   <MDBCardTitle className="tenby-five">Prijslijst</MDBCardTitle>
                                                   <MDBNav className="nav-tabs mt-3">
                                                       <MDBNavItem>
                                                           <Link to="dames" className={(this.state.activeItem === "1" ? 'active' : '')} onClick={this.toggle("1")} role="tab" >
                                                               Dames
                                                           </Link>
                                                       </MDBNavItem>
                                                       <MDBNavItem>
                                                           <Link to="heren" className={(this.state.activeItem === "2" ? 'active' : '')} onClick={this.toggle("2")} role="tab" >
                                                               Heren
                                                           </Link>
                                                       </MDBNavItem>
                                                       <MDBNavItem>
                                                           <Link to="kinderen" className={(this.state.activeItem === "3" ? 'active' : '')} onClick={this.toggle("3")} role="tab" >
                                                               Kinderen
                                                           </Link>
                                                       </MDBNavItem>
                                                   </MDBNav>
                                                   <MDBTabContent activeItem={this.state.activeItem} >
                                                       <MDBTabPane tabId="1" role="tabpanel">
                                                           <table>
                                                               <tbody>
                                                               {womanPriceItem}
                                                               </tbody>
                                                           </table>
                                                       </MDBTabPane>
                                                       <MDBTabPane tabId="2" role="tabpanel">
                                                           <table>
                                                               <tbody>
                                                                    {menPriceItem}
                                                               </tbody>
                                                           </table>
                                                       </MDBTabPane>
                                                       <MDBTabPane tabId="3" role="tabpanel">
                                                           <table>
                                                               <tbody>
                                                                    {childPriceItem}
                                                               </tbody>
                                                           </table>
                                                       </MDBTabPane>
                                                   </MDBTabContent>
                                               </MDBCardBody>
                                           </MDBCard>
                                       {/*</Router>*/}
                                   </div>
                               </div>
                           </div>
                       </div>
                   </StyledBackgroundSection>
               )
           }}
       />
       );
   }
}
