export default function getFrontmatter(data) {
    let found;
    if (Array.isArray(data)) {
      data.forEach(element => {
        if (typeof element.frontmatter !== 'undefined') {
          found = element.frontmatter;
        }
  
        let found2 = getFrontmatter(element);
  
        if (found2) {
          return found2;
        }
      });
    }
  
    if (typeof data === 'object') {
      for (let key in data) {
        if (key === 'frontmatter') {
          return data[key];
        }
  
        let found3 = getFrontmatter(data[key]);
  
        if (found3) {
          return found3;
        }
      }
    }
  
    return found;
  }